<template>
  <b-modal
    ref="refModalTrackingSalaryJob"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    hide-footer
    size="xmd"
    title="Payment Information History"
    @hidden="closeModal"
  >
    <div>
      <b-alert
        :variant="isDarkSkin? 'dark' : 'light'"
        show
        class="mb-1"
      >
        <h4 class="text-center py-1 text-success">
          Active Payment Method
          <b-badge
            variant="success"
          >
            {{ type_method.toUpperCase() }}
          </b-badge>
        </h4>
      </b-alert>
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          small
          show-empty
          :fields="fields"
          :items="accountActive"
          class="font-small-3 text-center"
        >
          <template #cell(payment_type)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.payment_type }}
            </p>
          </template>
          <template #cell(account_number)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.account_number == 'null' ? '-' : row.item.account_number }}
            </p>
          </template>
          <template #cell(account_type)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.account_type }}
            </p>
          </template>
          <template #cell(created_at)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.created_at | myGlobalWithHour }}
            </p>
          </template>
          <template #cell(currency)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.currency }}
            </p>
          </template>
          <template #cell(created_by)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.created_by }}
            </p>
          </template>
          <template #cell(edited_by)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.edited_by }}
            </p>
          </template>

        </b-table>

      </b-card>
      <b-alert
        :variant="isDarkSkin? 'dark' : 'light'"
        show
        class="my-1"
      >
        <h4 class="text-center text-danger py-1 font-weight-bold">
          History Payment Information
        </h4>
      </b-alert>
      <b-card
        variant="info"
        no-body
        class="mt-2"
      >
        <!-- History Payment Information -->

        <b-table
          small
          show-empty
          :fields="fields"
          :items="paymentInformationHistory"
          class="font-small-3 text-center"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-method="customSort"
        >
          <template #cell(payment_type)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.payment_type }}
            </p>
          </template>
          <template #cell(account_number)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.account_number == 'null' ? '-' : row.item.account_number }}
            </p>
          </template>
          <template #cell(account_type)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.account_type }}
            </p>
          </template>
          <template #cell(created_at)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.created_at }}
            </p>
          </template>
          <template #cell(currency)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.currency }}
            </p>
          </template>
          <template #cell(created_by)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.created_by }}
            </p>
          </template>
          <template #cell(edited_by)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.edited_by }}
            </p>
          </template>
        </b-table>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import EmployeesService from '@/views/management/views/employees/employees.service';

export default {
  props: {
    employee_id: {
      type: String,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type_method: '',
      paymentInformationHistory: [],
      accountActive: [],
      fields: [
        { key: 'payment_type', label: 'Method of payment' },
        { key: 'account_number', label: 'Account Number' },
        { key: 'account_type', label: 'Account Type' },
        { key: 'created_at', label: 'Created At' },
        { key: 'currency', label: 'Currency' },
        { key: 'created_by', label: 'Created By' },
        { key: 'edited_by', label: 'Edited By' },
      ],
    };
  },
  mounted() {
    this.toggleModal('refModalTrackingSalaryJob');
  },
  created() {
    this.getHistoryPaymentInfo();
  },
  methods: {

    customSort(field, order) {
    // Función de ordenamiento personalizada
      return this.salaries.sort((a, b) => {
      // Cambiar la propiedad 'created_at' según el nombre real en tu objeto 'salaries'
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        // Orden descendente
        if (order === 'desc') {
          return dateB - dateA;
        }

        // Orden ascendente (por defecto)
        return dateA - dateB;
      });
    },

    closeModal() {
      this.$emit('close');
    },
    async getHistoryPaymentInfo() {
      this.addPreloader();
      try {
        const { data } = await EmployeesService.getAllPaymentInformationHistory({
          employee_id: this.employee_id,
        });
        this.paymentInformationHistory = data === null ? [] : data;

        const filteredPaymentInfo = this.paymentInformationHistory.filter(obj => obj.updated_at === null && obj.preferred === 1);
        this.accountActive = filteredPaymentInfo.length > 0 ? filteredPaymentInfo : [];

        console.log(this.accountActive);
        this.type_method = !this.accountActive[0]?.payment_type ? 'no data recorded' : this.accountActive[0].payment_type;

        console.log(this.paymentInformationHistory);
        console.log(this.accountActive);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
