import { render, staticRenderFns } from "./PayInfoHistory.vue?vue&type=template&id=3ddc13d2"
import script from "./PayInfoHistory.vue?vue&type=script&lang=js"
export * from "./PayInfoHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports