<template>
  <div>
    <template>
      <div class="d-flex justify-content-end mb-1">
        <b-button
          v-if="edit === true"
          variant="primary"
          class="mr-1"
          @click="editEmployeeBasicInformation"
          >EDIT
        </b-button>
        <b-button
          v-if="edit === false"
          variant="success"
          class="mr-1"
          @click="updateJobDetails"
          >SAVE
        </b-button>
        <b-button
          v-if="edit === false"
          variant="danger"
          class="mr-1"
          @click="refresh"
          >CANCEL
        </b-button>
      </div>

      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%) !important"
      >
        <template #header>
          <b-card-title class="title-uwu"> START & DEPARTMENT </b-card-title>
        </template>
        <template #default>
          <validation-observer ref="form">
            <b-row>
              <!-- Start Date -->
              <b-col class="mt-1" md="6" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <b-input-group
                    :class="errors[0] ? 'border-danger rounded' : ''"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >Start Date
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <kendo-datepicker
                      v-if="finishedLoading"
                      v-model="userData.start_date"
                      v-mask="'##/##/####'"
                      class="flex-grow-1"
                      :format="'MM/dd/yyyy'"
                      :disabled="edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- Active -->
              <b-col class="mt-1" md="6" lg="6">
                <validation-provider name="Status" rules="required">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >Status
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-select
                      v-if="finishedLoading"
                      v-model="userData.status"
                      :disabled="edit"
                      value-field="status"
                      text-field="name"
                      :options="status"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>

              <b-col v-if="userData.status === 2" class="mt-1" md="6" lg="6">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="title_lable"
                      >Finish Date
                    </b-input-group-text>
                  </template>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="input"
                  />
                  <b-form-input
                    v-if="finishedLoading"
                    id="finish-date"
                    :value="dateNow"
                    style="font-size: 14px; color: #aeaeae"
                    name="finish-date"
                    disabled
                  />
                </b-input-group>
              </b-col>

              <!-- End Date -->
              <b-col class="mt-1" md="6" lg="6" v-if="userData.status === 2">
                <validation-provider
                  name="End Date"
                  v-slot="{ errors }"
                  :rules="userData.status === 2 ? 'required' : ''"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >End Date
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <kendo-datepicker
                      v-if="finishedLoading"
                      v-model="userData.end_date"
                      v-mask="'##/##/####'"
                      class="flex-grow-1"
                      :format="'MM/dd/yyyy'"
                      :disabled="edit"
                      :state="errors[0] ? false : null"
                      :class="errors[0] ? 'border-danger rounded' : ''"
                    />
                    <template #append>
                      <b-button
                        v-if="!edit && userData.end_date"
                        variant="danger"
                        @click="userData.end_date = null"
                      >
                        X
                      </b-button>
                    </template>
                  </b-input-group>
                </validation-provider>
              </b-col>

              <b-col class="mt-1" md="6" lg="6">
                <!-- Department-->
                <validation-provider
                  v-slot="{ errors }"
                  name="Department"
                  rules="required"
                >
                  <b-input-group
                    label="Department"
                    label-for="Department"
                    :state="errors[0] ? false : null"
                  >
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >Department
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />

                    <b-select
                      v-if="finishedLoading"
                      v-model="userData.department_id"
                      :disabled="edit"
                      value-field="id"
                      text-field="name"
                      :options="department"
                      @input="getRolByDepartment"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>

              <!-- Rol -->
              <b-col class="mt-1" md="6" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Rol"
                  rules="required"
                >
                  <b-input-group
                    label="Rol"
                    label-for="Rol"
                    :state="errors[0] ? false : null"
                  >
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >Rol
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />

                    <b-select
                      v-if="finishedLoading"
                      v-model="userData.rol_id"
                      :disabled="edit"
                      value-field="id"
                      text-field="name"
                      :options="optionsRol"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>

              <!-- Company -->
              <b-col class="mt-1" md="6" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Company"
                  rules="required"
                >
                  <b-input-group
                    label="Company"
                    label-for="Company"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  >
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >Company
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />

                    <b-select
                      v-if="finishedLoading"
                      v-model="userData.company_id"
                      :disabled="edit"
                      value-field="id"
                      text-field="business_name"
                      :options="company"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>

              <!-- Pension Fund -->
              <b-col class="mt-1" cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Pension Fund"
                  rules="required"
                >
                  <b-input-group
                    label="Pension Fund"
                    label-for="Pension Fund"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger rounded' : ''"
                  >
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >Pension Fund
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />

                    <b-select
                      v-if="finishedLoading"
                      v-model="userData.pf_id"
                      :disabled="edit"
                      value-field="id"
                      text-field="name"
                      :options="pfund"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>

              <!-- Has Family Allowance -->
              <b-col
                v-if="companyWithFamilyAllowance(userData.company_id)"
                class="d-flex justify-content-start align-items-center py-2"
                cols="6"
              >
                <validation-provider>
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="space flex-grow-1"
                    type="button"
                  />
                  <b-form-checkbox
                    id="checkbox-2"
                    v-model="userData.has_family_allowance"
                    name="checkbox-2"
                    value="1"
                    :disabled="edit"
                    unchecked-value="0"
                  >
                    Has Family Allowance?
                  </b-form-checkbox></validation-provider
                >
              </b-col>
              <b-col class="mt-1" md="6" lg="6">
                <!-- Observation-->
                <validation-provider name="Observation">
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text class="title_lable"
                        >Observation
                      </b-input-group-text>
                    </template>
                    <b-skeleton
                      v-if="!finishedLoading"
                      class="space flex-grow-1"
                      type="input"
                    />
                    <b-textarea
                      v-if="finishedLoading"
                      v-model="userData.observation"
                      :disabled="edit"
                    />
                  </b-input-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Start Date Sunat"
                  rules="required"
                >
                  <b-form-group
                    label="Start Date (*) Sunat"
                    label-for="start-date-sunat"
                  >
                    <b-skeleton v-if="!finishedLoading" type="input" />
                    <kendo-datepicker
                      v-if="finishedLoading"
                      v-model="userData.start_date_sunat"
                      v-mask="'##/##/####'"
                      :class="errors[0] ? 'border-danger rounded' : ''"
                      :format="'MM/dd/yyyy'"
                      :disabled="edit"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <!-- First Name -->
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="CUSPP"
                  rules="required"
                >
                  <b-form-group label="CUSPP" label-for="cuspp">
                    <b-skeleton v-if="!finishedLoading" type="input" />
                    <b-form-input
                      v-if="finishedLoading"
                      id="cuspp"
                      v-model="userData.cuspp"
                      name="cuspp"
                      autofocus
                      :state="errors[0] ? false : valid ? true : null"
                      trim
                      :disabled="edit"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </template>
      </b-card>
    </template>

    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%) !important"
      >
        <template #header>
          <b-card-title class="title-uwu"
            >SALARY
            <tabler-icon
              class="cursor-pointer"
              icon="ListIcon"
              size="25"
              @click="openTrackingModal"
            />
          </b-card-title>
        </template>
        <template #default>
          <b-row>
            <b-col>
              <b-row class="mb-1">
                <b-col lg="6">
                  <!-- CURRENCY-->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Rol"
                    rules="required"
                  >
                    <b-input-group :state="errors[0] ? false : null">
                      <template #prepend>
                        <b-input-group-text class="title_lable"
                          >Currency
                        </b-input-group-text>
                      </template>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="input"
                      />
                      <b-form-select
                        v-if="finishedLoading"
                        v-model="currencySelected"
                        disabled
                        value-field="currency"
                        text-field="name"
                        :options="optionsCurrency"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider name="Salary">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="title_lable"
                          >Salary
                        </b-input-group-text>
                      </template>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="input"
                      />
                      <money
                        v-if="finishedLoading"
                        v-model="userData.salary"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: prefix,
                          precision: 2,
                          masked: false,
                        }"
                        class="form-control form-control-md"
                        :disabled="edit == true || userData.salary_count > 0"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </template>

    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%) !important"
      >
        <template #header>
          <b-card-title class="title-uwu"> AGREEMENT </b-card-title>
        </template>
        <template #default>
          <b-row>
            <b-col>
              <b-row class="mb-1">
                <b-col md="6">
                  <!-- CURRENCY-->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Rol"
                    rules="required"
                  >
                    <b-input-group :state="errors[0] ? false : null">
                      <template #prepend>
                        <b-input-group-text class="title_lable"
                          >Type of agreement
                        </b-input-group-text>
                      </template>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="input"
                      />
                      <b-select
                        v-if="finishedLoading"
                        v-model="userData.type_of_agreement"
                        :disabled="edit"
                        value-field="type_of_agreement"
                        text-field="agreement_text"
                        :options="optionsAgreement"
                        @input="changeAgreement"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider name="Zip Code">
                    <b-input-group>
                      <template #prepend>
                        <b-input-group-text class="title_lable"
                          >Hours
                        </b-input-group-text>
                      </template>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="input"
                      />
                      <b-form-input
                        v-if="finishedLoading"
                        id="hours"
                        v-model="userData.hours"
                        name="hours"
                        :disabled="edit"
                      />
                    </b-input-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </template>

    <!-- Payment Information Section -->
    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%) !important"
      >
        <!-- Payment Information Title -->
        <template #header>
          <b-card-title class="title-uwu">
            PAYMENT INFORMATION
            <tabler-icon
              id="tooltip-payment-info-history"
              class="cursor-pointer"
              icon="ListIcon"
              size="25"
              @click="openPayInfoHistory"
            />
            <b-tooltip target="tooltip-payment-info-history" triggers="hover">
              Payment Information History
            </b-tooltip>
          </b-card-title>
        </template>
        <template #default>
          <b-row>
            <b-col>
              <b-row>
                <b-col md="12">
                  <!-- Payment Method-->

                  <validation-observer ref="formPaymentInformation">
                    <b-row>
                      <b-col md="6">
                        <!-- Payment Method -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Payment Method"
                          rules="required"
                        >
                          <b-form-group
                            label="Payment Method"
                            label-for="Payment Method"
                            :state="errors[0] ? false : null"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-select
                              v-if="finishedLoading"
                              v-model="userData.method"
                              value-field="method"
                              text-field="name"
                              :options="optionsMethod"
                              :disabled="edit"
                              :class="errors[0] ? 'border-danger rounded' : ''"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="userData.method === 1" md="3">
                        <!-- Transfer Prefered -->
                        <validation-provider
                          ref="preferedCurrencyForm"
                          v-slot="{ errors }"
                          name="Prefered Transfer"
                          rules="required"
                        >
                          <b-form-group label="Select a transfer type">
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="sapace flex-grow-1"
                              type="input"
                            />
                            <b-select
                              v-if="finishedLoading"
                              v-model="userData.prefered_transfer"
                              value-field="method"
                              text-field="name"
                              :options="optionsTransfer"
                              :disabled="edit"
                              :class="errors[0] ? 'border-danger rounded' : ''"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="userData.method === 1" md="3">
                        <!-- Currency Prefered -->
                        <validation-provider
                          ref="preferedCurrencyForm"
                          v-slot="{ errors }"
                          name="Prefered Currency"
                          rules="required"
                        >
                          <b-form-group label="Select a currency">
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="sapace flex-grow-1"
                              type="input"
                            />
                            <b-select
                              v-if="finishedLoading"
                              v-model="userData.prefered_currency"
                              value-field="currency"
                              text-field="name"
                              :options="optionsCurrency"
                              :disabled="edit"
                              :class="errors[0] ? 'border-danger rounded' : ''"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </validation-observer>

                  <validation-observer ref="formTransferInformation">
                    <b-row v-if="userData.method === 1">
                      <b-col v-if="userData.method === 1" md="12">
                        <b-button
                          variant="success"
                          size="sm"
                          :disabled="edit"
                          class="mb-1 btn-sm float-right"
                          @click="openFormAddBank"
                          >ADD NEW BANK
                        </b-button>
                      </b-col>
                      <b-col md="12" class="mt-1">
                        <h4>ACCOUNT INFORMATION IN SOLES (S/)</h4>
                      </b-col>
                      <b-col md="6" class="mt-1">
                        <!-- Bank Name Soles -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Bank Name Soles"
                          rules=""
                        >
                          <b-input-group
                            label="Bank Name"
                            label-for="Bank Name"
                            :state="errors[0] ? false : null"
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                Bank Name (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-select
                              v-if="finishedLoading"
                              v-model="userData.bank_name_soles"
                              :disabled="edit"
                              value-field="id"
                              text-field="name"
                              :options="bank"
                              @change="onOptionChangeSol"
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankSoles" md="6" class="mt-1">
                        <!-- Account Type Soles -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Account Type Soles"
                          rules="required"
                        >
                          <b-input-group
                            :state="errors[0] ? false : null"
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                Account type (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-select
                              v-if="finishedLoading"
                              v-model="userData.account_type_soles"
                              :disabled="edit"
                              value-field="method"
                              text-field="name"
                              :options="optionsAccounType"
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankSoles" md="6" class="mt-1">
                        <!-- Account Number Soles -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Account Number Soles"
                          rules="required|valid-number-account"
                        >
                          <b-input-group
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                Account Number (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id=""
                              v-model="userData.account_number_soles"
                              name=""
                              autofocus
                              :disabled="edit"
                              trim
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankSoles" md="6" class="mt-1">
                        <!-- CCI Soles -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="CCI Soles"
                          rules="required|valid-cci-code"
                        >
                          <b-input-group
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                CCI (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id=""
                              v-model="userData.account_cci_soles"
                              name=""
                              autofocus
                              :disabled="edit"
                              trim
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankSoles" md="2" class="mt-1">
                        <b-button
                          variant="outline-danger"
                          :disabled="edit"
                          @click="hideFormSol"
                        >
                          Remove Form
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row v-if="userData.method === 1">
                      <b-col md="12" class="mt-1">
                        <h4>ACCOUNT INFORMATION IN DOLLARS ($)</h4>
                      </b-col>
                      <b-col md="6" class="mt-1">
                        <!-- Bank Name Dollars -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Bank Name Dollars"
                          rules=""
                        >
                          <b-input-group
                            label="Bank Name"
                            label-for="Bank Name"
                            :state="errors[0] ? false : null"
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                Bank Name (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-select
                              v-if="finishedLoading"
                              v-model="userData.bank_name_dollars"
                              :disabled="edit"
                              value-field="id"
                              text-field="name"
                              :options="bank"
                              @change="onOptionChangeDollar"
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankDollars" md="6" class="mt-1">
                        <!-- Account Type Dollars -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Account Type Dollars"
                          rules="required"
                        >
                          <b-input-group
                            :state="errors[0] ? false : null"
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                Account type (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-select
                              v-if="finishedLoading"
                              v-model="userData.account_type_dollars"
                              :disabled="edit"
                              value-field="method"
                              text-field="name"
                              :options="optionsAccounType"
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankDollars" md="6" class="mt-1">
                        <!-- Account Number Dollars -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Account Number Dollars"
                          rules="required|valid-number-account"
                        >
                          <b-input-group
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                Account Number (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="hours"
                              v-model="userData.account_number_dollars"
                              v-mask="'##############'"
                              name="hours"
                              autofocus
                              :disabled="edit"
                              trim
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankDollars" md="6" class="mt-1">
                        <!-- CCI Dollars -->
                        <validation-provider
                          v-slot="{ errors }"
                          name="CCI Dollars"
                          rules="required|valid-cci-code"
                        >
                          <b-input-group
                            :class="errors[0] ? 'border-danger rounded' : ''"
                          >
                            <template #prepend>
                              <b-input-group-text class="title_lable">
                                CCI (*)
                              </b-input-group-text>
                            </template>
                            <b-skeleton
                              v-if="!finishedLoading"
                              class="space flex-grow-1"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="hours"
                              v-model="userData.account_cci_dollars"
                              v-mask="'####################'"
                              name="hours"
                              autofocus
                              :disabled="edit"
                              trim
                            />
                          </b-input-group>
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col v-if="showFormBankDollars" md="2" class="mt-1">
                        <b-button
                          variant="outline-danger"
                          :disabled="edit"
                          @click="hideFormDollar"
                        >
                          Remove Form
                        </b-button>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-col>
              </b-row>
            </b-col></b-row
          ></template
        >
      </b-card>

      <tracking-job-details
        v-if="modalTracking"
        :employee_id="$route.params.id"
        :currency="prefixCurrency"
        @close="closeTrackingModal"
      />
      <pay-info-history
        v-if="modalPayInfoModal"
        :employee_id="$route.params.id"
        @close="closePayInfoHistory"
      />
    </template>
    <template>
      <b-card
        border-variant="secondary"
        style="box-shadow: 0 1px 1px rgb(0 0 0 / 13%) !important"
      >
        <template #header>
          <b-card-title class="title-uwu"> WORK EXPERIENCIE </b-card-title>
        </template>
        <template #default>
          <b-row v-if="!userData.work_experiencie">
            <b-col cols="12">
              <h5 class="text-primary ml-1">Doesn't have Work Experience</h5>
            </b-col>
          </b-row>
          <template v-if="userData.work_experiencie">
            <b-row v-if="JSON.parse(userData.work_experiencie)">
              <b-col
                v-for="(item, index) in JSON.parse(userData.work_experiencie)"
                :key="index"
                cols="12"
                xl="6"
                class="rounded"
              >
                <div
                  class="w-100 py-1 px-2 rounded text-dark my-card position-relative my-1"
                >
                  <span
                    class="w-100 d-flex my-1"
                    style="font-size: 13px"
                    :style="isDarkSkin ? 'color: black;' : ''"
                  >
                    <feather-icon icon="CalendarIcon" class="mr-75" />
                    <b class="mr-1"> Date From : </b>
                    {{ item.dateFrom | myGlobal }}
                  </span>
                  <span
                    class="w-100 d-flex my-1"
                    style="font-size: 13px"
                    :style="isDarkSkin ? 'color: black;' : ''"
                  >
                    <feather-icon icon="CalendarIcon" class="mr-75" />
                    <b class="mr-1"> Date End : </b
                    >{{ item.dateEnd | myGlobal }}
                  </span>
                  <span
                    class="w-100 d-flex my-1"
                    style="font-size: 13px"
                    :style="isDarkSkin ? 'color: black;' : ''"
                  >
                    <feather-icon icon="CalendarIcon" class="mr-75" />
                    <b class="mr-1">Role : </b>
                    {{ item.role }}
                  </span>
                  <p
                    class="w-100 d-flex flex-column p-0"
                    style="font-size: 13px"
                    :style="isDarkSkin ? 'color: black;' : ''"
                  >
                    <b>
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      My functions were :</b
                    >
                    <span
                      class="px-2 py-1"
                      :style="isDarkSkin ? 'color: black !important;' : ''"
                    >
                      {{
                        item.function.length > limit &&
                        indexDescription === index
                          ? item.function
                          : item.function.substring(0, limit)
                      }}
                      <template v-if="item.function.length > limit">
                        <b-button
                          v-if="indexDescription !== index"
                          size="sm"
                          variant="link"
                          class="p-0 font-weight-bolder border-bottom-primary rounded-0"
                          @click="indexDescription = index"
                        >
                          ... Show more
                        </b-button>
                        <b-button
                          v-else
                          size="sm"
                          variant="link"
                          class="p-0 font-weight-bolder border-bottom-primary rounded-0"
                          @click="indexDescription = null"
                        >
                          Hidden
                        </b-button>
                      </template>
                    </span>
                  </p>
                </div>
              </b-col>
            </b-row>
          </template>
        </template>
      </b-card>
    </template>
    <add-new-bank
      v-if="openAddNewModal"
      @close="closeModalAddBank"
      @saved="getAllBanks"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { createPopper } from "@popperjs/core";
import EmployeesService from "@/views/management/views/employees/employees.service";
import TrackingJobDetails from "@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/modals/Tracking/TrackingJobDetails.vue";
import AddNewBank from "@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/modals/AddNewBank/AddNewBank.vue";
import PayInfoHistory from "@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/modals/PayInfoHistory/PayInfoHistory.vue";

export default {
  components: {
    TrackingJobDetails,
    PayInfoHistory,
    AddNewBank,
  },
  data() {
    return {
      defaultSelected: 1,
      currencySelected: 2,
      optionsPrefered: [
        { text: "SOLES", value: 1 },
        { text: "DOLLARS", value: 2 },
      ],

      showFormBankSoles: false,
      showFormBankDollars: false,

      bankAccounts: [],
      openAddNewModal: false,
      payment_data: [],

      bankname: "",

      showAddBank: false,
      finishedLoading: false,
      modalTracking: false,

      modalPayInfoModal: false,

      gender: [
        { id: 1, gender: "Female" },
        { id: 2, gender: "Male" },
      ],
      status: [
        { status: 1, name: "Active" },
        { status: 2, name: "Inactive" },
      ],
      optionsCurrency: [
        { currency: 1, name: "$ (Dollar)" },
        { currency: 2, name: "S./ (Soles)" },
      ],
      optionsCurrencyProvincion: [
        { currency_provision: 1, name: "$ (Dollar)" },
        {
          currency_provision: 2,
          name: "S./ (Soles)",
        },
      ],
      optionsTypeProvision: [
        { type_provision: 1, provision: "Percent" },
        { type_provision: 2, provision: "Amount" },
      ],
      optionsAgreement: [
        { type_of_agreement: 1, agreement_text: "Hours" },
        {
          type_of_agreement: 2,
          agreement_text: "Full",
        },
      ],
      optionsMethod: [
        { method: 1, name: "Transfer" },
        { method: 2, name: "Deposit" },
        { method: 3, name: "Cash" },
        { method: 4, name: "Check" },
      ],

      optionsTransfer: [
        { method: 1, name: "Direct" },
        { method: 2, name: "Interbank" },
      ],

      optionsAccounType: [
        { method: 1, name: "Current" },
        { method: 2, name: "Master" },
        { method: 3, name: "Savings" },
      ],

      userData: { file: "", currency: 1, salary: 0 },
      valor: "0.00",
      relations: [],
      department: [],
      martial: [],
      document: [],
      image: null,
      optionsRol: [],
      edit: true,
      changeInput: null,
      noModifyUserData: [],
      jobDetailsRefresh: [],
      bank: [],
      company: [],
      pfund: [],
      indexDescription: null,
      limit: 100,
      temporaryEndDate: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    dateNow() {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      // if month is less than 10, add a zero before
      const monthWithZero = month < 10 ? `0${month}` : month;
      // if day is less than 10, add a zero before
      const dayWithZero = day < 10 ? `0${day}` : day;
      const dateNow = `${monthWithZero}/${dayWithZero}/${year}`;
      const finishDate = this.userData.finish_date;
      if(finishDate){
        const finishDateFormated = finishDate.split('-').reverse().join('/');
        return finishDateFormated;
      } else {
        return dateNow;
      }
    },

    prefix() {
      const currency = {
        1: "$",
        2: "S/.",
      };
      return currency[this.userData.currency];
    },

    prefixCurrency() {
      const currency = {
        1: "$",
        2: "S/.",
      };
      return currency[this.userData.currency_provision];
    },
  },

  watch: {
    payment_data(newVal, oldVal) {
      if (
        newVal === undefined ||
        newVal === null ||
        oldVal === undefined ||
        oldVal === null
      ) {
        this.getAllBankAccounts();
      }
    },
  },

  async created() {
    await this.getAllDocuments();
    await this.getAllMartial();
    await this.getAllRelations();
    await this.getAllDepartments();
    await this.getRolByDepartment();
    await this.getAllCompanies();
    await this.getAllPensionFund();
    await this.getAllBanks();
    await this.getJobDetailsEmployeer();
    this.changeTypeProvision();
    this.finishedLoading = true;
  },

  methods: {
    companyWithFamilyAllowance(id) {
      const selectedCompany = this.company.find((item) => item.id === id);
      if (selectedCompany) {
        return selectedCompany.family_allowance === 1;
      }
      return false;
    },

    onOptionChangeSol() {
      this.showFormBankSoles = !!this.userData.bank_name_soles;
    },

    hideFormSol() {
      this.userData.bank_name_soles = "";
      this.userData.account_type_soles = null;
      this.userData.account_number_soles = null;
      this.userData.account_cci_soles = null;
      this.showFormBankSoles = false;
    },

    onOptionChangeDollar() {
      this.showFormBankDollars = !!this.userData.bank_name_dollars;
    },

    hideFormDollar() {
      this.userData.bank_name_dollars = "";
      this.userData.account_type_dollars = null;
      this.userData.account_number_dollars = null;
      this.userData.account_cci_dollars = null;
      this.showFormBankDollars = false;
    },

    openFormAddBank() {
      this.openAddNewModal = true;
    },

    closeModalAddBank() {
      this.openAddNewModal = false;
      this.bankname = null;
    },

    openTrackingModal() {
      this.modalTracking = true;
    },

    closeTrackingModal() {
      this.modalTracking = false;
    },

    openPayInfoHistory() {
      this.modalPayInfoModal = true;
    },

    closePayInfoHistory() {
      this.modalPayInfoModal = false;
    },

    calculatePorcentage() {
      const percentageProvision = Math.min(
        this.userData.percentage_provision,
        100
      );
      this.userData.provision =
        this.userData.salary * (percentageProvision / 100);
    },

    withPopper(dropdownList, component, { width }) {
      const dropdownListCopy = { ...dropdownList };
      dropdownListCopy.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownListCopy, {
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },

    getAllBankAccounts() {
      const data = [
        {
          employee_account_id: this.payment_data[0]?.employee_account_id,
          payment_type: this.userData.method,
          subtype: "Direct",
          currency: "PEN",
          account_number: this.userData.account_number_soles,
          bank_account_id: this.userData.bank_name_soles,
          account_type: this.userData.account_type_soles,
          preferred:
            this.userData.prefered_currency === 2 &&
            this.userData.prefered_transfer === 1
              ? 1
              : 0,
        },
        {
          employee_account_id: this.payment_data[1]?.employee_account_id,
          payment_type: this.userData.method,
          subtype: "Interbank",
          currency: "PEN",
          account_number: this.userData.account_cci_soles,
          bank_account_id: this.userData.bank_name_soles,
          account_type: this.userData.account_type_soles,
          preferred:
            this.userData.prefered_currency === 2 &&
            this.userData.prefered_transfer === 2
              ? 1
              : 0,
        },
        {
          employee_account_id: this.payment_data[2]?.employee_account_id,
          payment_type: this.userData.method,
          subtype: "Direct",
          currency: "USD",
          account_number: this.userData.account_number_dollars,
          bank_account_id:
            this.userData.bank_name_dollars !== null
              ? this.userData.bank_name_dollars
              : null,
          account_type: this.userData.account_type_dollars,
          preferred:
            this.userData.prefered_currency === 1 &&
            this.userData.prefered_transfer === 1
              ? 1
              : 0,
        },
        {
          employee_account_id: this.payment_data[3]?.employee_account_id,
          payment_type: this.userData.method,
          subtype: "Interbank",
          currency: "USD",
          account_number: this.userData.account_cci_dollars,
          bank_account_id:
            this.userData.bank_name_dollars !== null
              ? this.userData.bank_name_dollars
              : null,
          account_type: this.userData.account_type_dollars,
          preferred:
            this.userData.prefered_currency === 1 &&
            this.userData.prefered_transfer === 2
              ? 1
              : 0,
        },
      ];
      return data;
    },

    async postBank() {
      const data = {
        bank_name: this.bankname,
      };

      try {
        const response = await EmployeesService.postBank(data);
        if (response.status === 200) {
          this.closeModalAddBank();
          this.showAddBank = false;
          this.bankname = null;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
      return [];
    },

    async getAllRelations() {
      try {
        const data = await EmployeesService.getAllRelations();

        this.relations = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.relations;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllDocuments() {
      try {
        const data = await EmployeesService.getAllDocuments();

        this.document = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.document;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllMartial() {
      try {
        const data = await EmployeesService.getAllMartial();

        this.martial = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.martial;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllDepartments() {
      try {
        const data = await EmployeesService.getAllDepartments();

        this.department = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.department;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getRolByDepartment() {
      try {
        const params = {
          id: this.userData.department_id,
        };
        const data = await EmployeesService.getRolByDepartment(params);

        this.optionsRol = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.optionsRol;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllCompanies() {
      try {
        const data = await EmployeesService.getAllCompanies();

        this.company = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.company;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllPensionFund() {
      try {
        const data = await EmployeesService.getAllPensionFund();

        this.pfund = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.pfund;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllPaymentInformation() {
      try {
        const data = await EmployeesService.getAllPaymentInformation();

        this.pfund = JSON.parse(data.data);
        // Must return an array of items or an empty array if an error occurred

        return this.pfund;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllBanks() {
      try {
        const data = await EmployeesService.getAllBanks();

        this.bank = data.data;
        // Must return an array of items or an empty array if an error occurred
        return this.bank;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async editEmployeeBasicInformation() {
      this.edit = !this.edit;
    },

    changeTypeProvision() {
      if (this.userData.type_provision === 1) {
        this.changeInput = true;
      } else if (this.userData.type_provision === 2) {
        this.changeInput = false;
      }
    },

    async updateJobDetails() {
      try {
        switch (this.userData.method) {
          case 1:
            this.bankAccounts = this.getAllBankAccounts();
            break;
          case 2:
            this.bankAccounts = [
              {
                // Check Method
                employee_account_id: this.payment_data[0]?.employee_account_id,
                payment_type: this.userData.method,
                subtype: "Direct",
                currency: "PEN",
                account_number: null,
                bank_account_id: null,
                account_type: "Current",
                preferred: 1,
              },
            ];
            break;
          case 3:
            this.bankAccounts = [
              {
                // Deposit Method
                employee_account_id: this.payment_data[0]?.employee_account_id,
                payment_type: this.userData.method,
                subtype: "Direct",
                currency: "PEN",
                account_number: "",
                bank_account_id: null,
                account_type: "Current",
                preferred: 1,
              },
            ];
            break;
          case 4:
            this.bankAccounts = [
              {
                // Cash Method
                employee_account_id: this.payment_data[0]?.employee_account_id,
                payment_type: this.userData.method,
                subtype: "Direct",
                currency: "PEN",
                account_number: "",
                bank_account_id: null,
                account_type: "Current",
                preferred: 1,
              },
            ];
            break;
          default:
            this.bankAccounts = [];
            break;
        }

        const arraysModified = [];

        const result = await this.$refs.form.validate();
        // const resultPreferedCurrencyForm = await this.$refs.preferedCurrencyForm.validate();
        const resultPaymentInformation =
          await this.$refs.formPaymentInformation.validate();
        const resultTransferInformation =
          await this.$refs.formTransferInformation.validate();
        if (!result) {
          this.$refs.form.validate().then((success) => {
            if (!success) {
              setTimeout(() => {
                const errors = Object.entries(this.$refs.form.errors)
                  .map(([key, value]) => ({ key, value }))
                  .filter((error) => error.value.length);
                this.$refs.form.refs[errors[0].key].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 100);
            }
          });
          return;
        }

        if (!resultTransferInformation) {
          this.$refs.formTransferInformation.validate().then((success) => {
            if (!success) {
              setTimeout(() => {
                const errors = Object.entries(
                  this.$refs.formTransferInformation.errors
                )
                  .map(([key, value]) => ({ key, value }))
                  .filter((error) => error.value.length);
                this.$refs.formTransferInformation.refs[
                  errors[0].key
                ].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 100);
            }
          });
          return;
        }

        if (!resultPaymentInformation) {
          this.$refs.formPaymentInformation.validate().then((success) => {
            if (!success) {
              setTimeout(() => {
                const errors = Object.entries(
                  this.$refs.formPaymentInformation.errors
                )
                  .map(([key, value]) => ({ key, value }))
                  .filter((error) => error.value.length);
                this.$refs.formPaymentInformation.refs[
                  errors[0].key
                ].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 100);
            }
          });
          return;
        }

        if (
          result &&
          resultPaymentInformation &&
          resultTransferInformation /*  && resultPreferedCurrencyForm */
        ) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            if (this.userData.hours !== this.noModifyUserData.hours) {
              arraysModified.push({
                modified: "Hours",
                previous: this.noModifyUserData.hours,
                change: this.userData.hours,
              });
            }
            if (this.userData.currency !== this.noModifyUserData.currency) {
              let previous;
              if (this.userData.currency === 1) {
                previous = "Dollar";
              } else if (this.userData.currency === 2) {
                previous = "Soles";
              } else {
                previous = "";
              }
              arraysModified.push({
                modified: "Currency Salary",
                previous,
                change:
                  this.noModifyUserData.currency === 1 ? "Dollar" : "Soles",
              });
            }
            if (this.userData.salary !== this.noModifyUserData.salary) {
              arraysModified.push({
                modified: "Salary",
                previous: this.noModifyUserData.salary,
                change: this.userData.salary.toFixed(2),
              });
            }
            if (
              this.userData.type_provision !==
              this.noModifyUserData.type_provision
            ) {
              let previous;
              if (this.noModifyUserData.type_provision === 1) {
                previous = "Percent";
              } else if (this.noModifyUserData.type_provision === 2) {
                previous = "Amount";
              } else {
                previous = "";
              }
              arraysModified.push({
                modified: "Type provision",
                previous,
                change:
                  this.userData.type_provision === 1 ? "Percent" : "Amount",
              });
            }
            if (
              this.userData.current_provision !==
              this.noModifyUserData.current_provision
            ) {
              let previousValue = "";
              if (this.noModifyUserData.current_provision === 1) {
                previousValue = "Dollar";
              } else if (this.noModifyUserData.current_provision === 2) {
                previousValue = "Soles";
              }

              arraysModified.push({
                modified: "Currency provision",
                previous: previousValue,
                change:
                  this.userData.current_provision === 1 ? "Dollar" : "Soles",
              });
            }
            if (this.userData.provision !== this.noModifyUserData.provision) {
              arraysModified.push({
                modified: "Provision",
                previous: this.noModifyUserData.provision,
                change: this.userData.provision,
              });
            }
            if (
              this.userData.type_of_agreement !==
              this.noModifyUserData.type_of_agreement
            ) {
              let previous;
              if (this.noModifyUserData.type_of_agreement === 1) {
                previous = "Hours";
              } else if (this.noModifyUserData.type_of_agreement === 2) {
                previous = "Full";
              } else {
                previous = null;
              }

              let change;
              if (this.userData.type_of_agreement === 1) {
                change = "Hours";
              } else if (this.userData.type_of_agreement === 2) {
                change = "Full";
              } else {
                change = null;
              }

              arraysModified.push({
                modified: "Type of agreement",
                previous,
                change,
              });
            }

            const params = {
              id: this.$route.params.id,
              accountnumber: this.userData.account,
              hours: this.userData.hours,
              salary: this.userData.salary,
              bank: this.userData.bank,
              salaryType: this.userData.currency,
              modified: arraysModified,
              departament: this.userData.department_id,
              finish_date: this.userData.finish_date,
              method: this.userData.method,
              observation: this.userData.observation,
              role: this.userData.rol_id,
              start_date: this.userData.start_date,
              status: this.userData.status,
              user_id: this.currentUser.user_id,
              end_date: this.userData.end_date,
              type_provision: this.userData.type_provision,
              currencyProvision: this.userData.currency_provision,
              provision: this.userData.provision,
              porcentage: this.userData.percentage_provision,
              initial_provision: this.userData.initial_provision,
              current_provision: this.userData.current_provision
                ? this.userData.current_provision
                : this.userData.initial_provision,
              typeAgreement: this.userData.type_of_agreement,

              company_id: this.userData.company_id,
              pf_id: this.userData.pf_id,

              payment_information: this.bankAccounts,
              has_family_allowance: this.userData.has_family_allowance,

              start_date_sunat: this.userData.start_date_sunat,
              cuspp: this.userData.cuspp,
            };
            this.addPreloader();
            const data = await EmployeesService.updateJobDetails(params);

            if (data.status === 200) {
              await this.$store.dispatch(
                "ManagementEmployeesStore/A_EMPLOYEE_INFO",
                this.$route.params.id
              );
              await this.refresh();
              await this.getJobDetailsEmployeer();

              this.showSuccessSwal(data.data.message, "");
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
      return [];
    },
    changeAgreement() {
      if (this.userData.type_of_agreement === 1) {
        this.userData.hours = 184;
      } else {
        this.userData.hours = null;
      }
    },
    async refresh() {
      this.edit = true;
      this.userData = { ...this.noModifyUserData };
      await this.getJobDetailsEmployeer();
      this.temporaryEndDate = null;
    },
    async getJobDetailsEmployeer() {
      try {
        const params = {
          id: this.$route.params.id,
        };
        const data = await EmployeesService.getJobDetailsInformation(params);
        this.noModifyUserData = { ...data.data[0] };

        [this.userData] = data.data;

        this.userData.salary = this.userData.salary ? this.userData.salary : 0;

        this.userData.current_provision = this.userData.current_provision
          ? this.userData.current_provision
          : 0;
        this.userData.initial_provision = this.userData.initial_provision
          ? this.userData.initial_provision
          : 0;

        this.payment_data = data.data[0].payment_information_data
          ? JSON.parse(data.data[0].payment_information_data)
          : [];
        // VALIDAR DATOS DE TRNSFER
        if (this.payment_data.length === 4) {
          // Validar preferencia de transfer y currency
          if (
            this.payment_data[0]?.preferred === 0 &&
            this.payment_data[1]?.preferred === 0 &&
            this.payment_data[2]?.preferred === 0 &&
            this.payment_data[3]?.preferred === 0
          ) {
            this.userData.prefered_transfer = 1;
            this.userData.prefered_currency = 2;
          } else {
            if (this.payment_data[0]?.preferred === 1) {
              this.userData.prefered_transfer = 1;
              this.userData.prefered_currency = 2;
            }
            if (this.payment_data[1]?.preferred === 1) {
              this.userData.prefered_transfer = 2;
              this.userData.prefered_currency = 2;
            }
            if (this.payment_data[2]?.preferred === 1) {
              this.userData.prefered_transfer = 1;
              this.userData.prefered_currency = 1;
            }
            if (this.payment_data[3]?.preferred === 1) {
              this.userData.prefered_transfer = 2;
              this.userData.prefered_currency = 1;
            }
          }

          if (this.payment_data[0]?.bank_account_id !== null) {
            this.showFormBankSoles = true;
          }

          if (this.payment_data[2]?.bank_account_id !== null) {
            this.showFormBankDollars = true;
          }

          switch (this.payment_data[0]?.payment_type) {
            case "Transfer":
              this.userData.method = 1;
              break;
            case "Deposit":
              this.userData.method = 2;
              break;
            case "Cash":
              this.userData.method = 3;
              break;
            case "Check":
              this.userData.method = 4;
              break;
            default:
              this.userData.method = null;
              break;
          }

          // Verificar si payment_data[0] y payment_data[2] existen antes de acceder a sus propiedades
          this.userData.bank_name_soles = this.payment_data[0]?.bank_account_id;

          this.userData.bank_name_dollars =
            this.payment_data[2]?.bank_account_id;

          switch (this.payment_data[0]?.account_type) {
            case "Current":
              this.userData.account_type_soles = 1;
              break;
            case "Principal":
              this.userData.account_type_soles = 2;
              break;
            case "Savings":
              this.userData.account_type_soles = 3;
              break;
            case "Interbank":
              this.userData.account_type_soles = 4;
              break;
            default:
              this.userData.account_type_soles = null;
          }

          switch (this.payment_data[2]?.account_type) {
            case "Current":
              this.userData.account_type_dollars = 1;
              break;
            case "Principal":
              this.userData.account_type_dollars = 2;
              break;
            case "Savings":
              this.userData.account_type_dollars = 3;
              break;
            case "Interbank":
              this.userData.account_type_dollars = 4;
              break;
            default:
              this.userData.account_type_dollars = null;
          }

          // Verificar si payment_data[0] y payment_data[2] existen antes de acceder a sus propiedades
          this.userData.account_number_soles =
            this.payment_data[0]?.account_number;
          this.userData.account_number_dollars =
            this.payment_data[2]?.account_number;

          // Verificar si payment_data[1] y payment_data[3] existen antes de acceder a sus propiedades
          this.userData.account_cci_soles =
            this.payment_data[1]?.account_number;
          this.userData.account_cci_dollars =
            this.payment_data[3]?.account_number;
        } else if (this.payment_data.length === 1) {
          if (
            this.payment_data[0]?.preferred === 0 &&
            this.payment_data[0]?.currency === "USD"
          ) {
            this.userData.prefered_currency = 1;
            this.userData.prefered_transfer = 1;
          }

          if (
            this.payment_data[0]?.preferred === 0 &&
            this.payment_data[0]?.currency === "PEN"
          ) {
            this.userData.prefered_currency = 2;
            this.userData.prefered_transfer = 1;
          }

          if (
            this.payment_data[0]?.bank_account_id !== null &&
            this.payment_data[0]?.currency === "PEN"
          ) {
            this.showFormBankSoles = true;
          }

          if (
            this.payment_data[0]?.bank_account_id !== null &&
            this.payment_data[0]?.currency === "USD"
          ) {
            this.showFormBankDollars = true;
          }
          switch (this.payment_data[0]?.payment_type) {
            case "Transfer":
              this.userData.method = 1;
              break;
            case "Deposit":
              this.userData.method = 2;
              break;
            case "Cash":
              this.userData.method = 3;
              break;
            case "Check":
              this.userData.method = 4;
              break;
            default:
              this.userData.method = null;
              break;
          }
          if (
            this.payment_data[0]?.currency === "USD" &&
            this.payment_data[0]?.payment_type === "Transfer"
          ) {
            this.userData.account_number_dollars =
              this.payment_data[0]?.account_number;
            this.userData.bank_name_dollars =
              this.payment_data[0]?.bank_account_id;
          }
          if (
            this.payment_data[0]?.currency === "PEN" &&
            this.payment_data[0]?.payment_type === "Transfer"
          ) {
            this.userData.account_number_soles =
              this.payment_data[0]?.account_number;
            this.userData.bank_name_soles =
              this.payment_data[0]?.bank_account_id;
          }
        }

        // Verificar si payment_data[0] existe antes de acceder a sus propiedades
        return this.userData;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

#overpx .vs__dropdown-toggle .vs__selected-options .vs__search {
  margin: 9.15px 0 0 !important;
}

.title_lable {
  width: 200px;
}

@media (max-width: 1366px) {
  .space {
    width: 47.5% !important;
  }

  .title_lable {
    width: 183px;
  }
}

@media (max-width: 1024px) {
  .space {
    width: auto;
  }

  .title_lable {
    width: 183px;
  }
}

.custom-caro.k-input {
  max-width: 218px !important;
  width: 300px !important;
}

.custom-caro2.k-input {
  max-width: 322px !important;
  width: 322px !important;
}
.my-card {
  background-image: radial-gradient(
    circle farthest-corner at 5.3% 17.2%,
    rgb(208, 252, 255) 0%,
    rgb(134, 162, 238) 90%
  );
}
</style>
