<template>

  <b-modal
    v-model="showAddBank"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    hide-footer
    size="md"
    title="Add New Bank Name"
    @hidden="closeModalAddBank"
  >
    <div>
      <b-card
        no-body
        class="mb-0"
      >
        <validation-observer ref="FormAddBank">
          <b-row>
            <b-col md="10">
              <validation-provider name="AccountNumber" v-slot="{errors}" rules="required">
                <b-input-group
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger rounded' : ''"
                >
                  <template #prepend>
                    <b-input-group-text class="title_lable">Bank Name
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="bank_name"
                    v-model="bankname"
                    rules="required"
                    name="bank_name"
                    @keyup.enter="postBank"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              
            </b-col>
            <b-col md="2">
              <b-button
                class="md"
                variant="success"
                @click="postBank"
              >
                Save
              </b-button>
            </b-col>
            <b-col md="12">
              <div class="my-1">
  <b-alert show dismissible variant="info">
    <h4 class="alert-heading">Before adding a bank check this list.</h4>
  </b-alert>
</div>
            </b-col>
            <b-col md="12">
              <b-table
          small
          show-empty
          :fields="fields"
          :items="bankList"
          class="font-small-3 text-center"
        >
          <template #cell(id)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.id }}
            </p>
          </template>
          <template #cell(name)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.name }}
            </p>
          </template>
          <template #cell(created_at)="row">
            <p class="mb-0 font-weight-bold">
              {{ row.item.created_at }}
            </p>
          </template>
          
          

        </b-table>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </div>
  </b-modal>

</template>
<script>

import EmployeesService from '@/views/management/views/employees/employees.service';

export default {
  data() {
    return {
      bankList: [],
      showAddBank: false,
      bankname: '',
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Bank' },
        { key: 'created_at', label: 'Created_at' },
      ],
    };
  },

  mounted() {
    this.showAddBank = true;
    this.getAllBanks();
  },

  methods: {
    closeModalAddBank() {
      this.showAddBank = false;
      this.$emit('close');
      this.$emit('saved');
    },

    async postBank() {


      const result = await this.$refs.FormAddBank.validate();
      if (result) {
        const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            const data = {
        bank_name: this.bankname,
      };

      try {
        this.addPreloader();
        const response = await EmployeesService.postBank(data);
        if (response.status === 200) {
          this.closeModalAddBank();
          this.showAddBank = false;
          this.bankname = null;
          this.$emit('saved');
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }

          }

      }
            return [];
    },

    async getAllBanks(){
      try {
        const data = await EmployeesService.getAllBanks();
        console.log(data)
        // eslint-disable-next-line no-param-reassign

        this.bankList = data.data;
        // Must return an array of items or an empty array if an error occurred
        return this.bank;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    }
  },
};
</script>
