<template>
  <b-modal
    ref="refModalTrackingSalaryJob"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    hide-footer
    size="xmd"
    title="TRACKING"
    @hidden="closeModal"
  >
    <div>
      <b-card no-body class="mb-0">
        <b-table
          small
          show-empty
          :fields="fields"
          :items="salaries"
          class="font-small-3 text-center"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template v-slot:cell(old_salary)="data">
            <p class="mb-0 font-weight-bold">
              {{ currency }} {{ data.item.old_salary | currency }}
            </p>
          </template>
          <template v-slot:cell(salary)="data">
            <p class="mb-0 font-weight-bold">
              {{ currency }} {{ data.item.salary | currency }}
            </p>
          </template>
          <template v-slot:cell(increment_date)="data">
            <p class="mb-0 font-weight-bold">
              {{ data.item.created_at | myGlobalDay }}
            </p>
          </template>
          <template v-slot:cell(actions)="data">
            <feather-icon
              icon="TrashIcon"
              class="clickable text-danger"
              v-b-tooltip.hover.top="'Delete Salary'"
              @click="removeSalary(data.item.id)"
            />
          </template>
        </b-table>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js"
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue"

export default {
  components: { FeatherIcon },
  props: {
    employee_id: {
      type: String,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    remove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      salaries: [],
      fields: [
        { key: "created_by", label: "Edited By" },
        { key: "old_salary", label: "Old Salary" },
        { key: "salary", label: "New Salary" },
        { key: "increment_date", label: "Date" },
        { key: "actions", label: "Actions" },
      ],
    }
  },
  mounted() {
    this.toggleModal("refModalTrackingSalaryJob")
  },
  created() {
    this.getTracking()
    this.fields = this.remove ? this.fields : this.fields.slice(0, 4)
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    async getTracking() {
      this.addPreloader()
      try {
        const { data } = await SalaryRecordService.getSalariesChart({
          employee_id: this.employee_id,
        })
        this.salaries = data.filter((item) => item.status == 2)
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },

    async removeSalary(id) {
      const confirm = await this.showConfirmSwal()
      if (!confirm.value) return
      this.addPreloader()
      try {
        await SalaryRecordService.removeSalaryRecord({
          id,
        })
        this.getTracking()
        this.$emit("remove-salary")
        this.showSuccessSwal("Salary removed successfully")
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader()
      }
    },
  },
}
</script>
